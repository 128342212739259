import React from 'react'
import Main from './components/Main'
import './App.scss'

function App() {
  return (
    <div className="App flex min-h-screen flex-col">
      <Main />
    </div>
  )
}

export default App
